.container {
  background-color: lightblue;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  .loginContainerGrid {
    height: 100vh;
  }

  .loginCard {
    width: 300px;
    height: auto;
  }

  .title {
    background-color: var(--primary);
    color: white;
    padding: 8px;
  }
}
